import { FunctionComponent, HTMLAttributes, ReactElement } from "react";

import { styled } from "@mui/system";
import banner from "./banner.jpg";

interface Properties extends HTMLAttributes<HTMLElement> {}

const Container = styled("div")({
    height: 325,
    backgroundImage: `url(${banner})`,
    backgroundRepeat: "no-repeat",
    marginTop: "-40px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
});

export const Banner: FunctionComponent<Properties> = ({
    children,
    className,
    ...rest
}: Properties): ReactElement => {
    return (
        <Container
            data-testid="artuner-banner-wrapper"
            className={className}
            {...rest}
        >
            {children}
        </Container>
    );
};
